import Vue from 'vue'

class MeterDeviceRepository {
  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  attachMeterDevice(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/member/attach-meter-device',
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  detachMeterDevice(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/member/detach-meter-device',
        method: 'post',
        data
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/' + id,
        method: 'put',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/' + id,
        method: 'delete',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/get/' + id,
        method: 'get',
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  metertypes() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/types',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getdomoconsometers() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/domoconso/meters',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  checkMeterDevicesReadingsToken(token) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/check-meter-devices-readings/' + token,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  importCdrs(type,file) {
    return new Promise((resolve, reject) => {

      var data = new FormData();
      data.append('file', file)
      data.append('type', type)
      data.append('action', 'import')

      Vue.auth.fetch({
        url: `/api/meter-device/import-cdrs`,
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  createCdrsInZMS(cdrsFromFile,type) {
    let  data={cdrs: cdrsFromFile, action:'creation', type:type }
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device/import-cdrs',
        method: 'post',
        data,
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  addManualMeterReadings(token, meterReadings) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();

      // Ajoutez les données normales (non binaires) à FormData
      formData.append('token', JSON.stringify(token));
      formData.append('meterDevicesReadings', JSON.stringify(meterReadings));

      // Ajoutez les fichiers (le cas échéant)
      meterReadings.forEach((reading, index) => {
        if (reading.picture) {
          formData.append(`pictures[${index}]`, reading.picture);
        }
      });

      Vue.auth.fetch({
        url: '/api/add-meter-devices-readings-with-token/',
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        resolve(response.data);
      }).catch(err => {
        reject(err);
      });
    });
  }

  searchMeterDeviceReadings(meterDevice, organization, status) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/get-meter-devices-readings/search',
        method: 'post',
        data: {
          meterDeviceId: meterDevice?.id,
          organizationId: organization?.id,
          status,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getMeterDeviceReadingPicture(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device-reading/' + id + '/picture',
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  acceptReadings(readings) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-devices-readings/accept',
        method: 'post',
        data: { ids: readings }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  rejectReadings(readings) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-devices-readings/reject',
        method: 'post',
        data: { ids: readings }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateReading(readingId, meterDeviceId, readingValue = null, readingDate = null) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/meter-device-reading/' + readingId + '/change-infos',
        method: 'put',
        data: {
          readingValue,
          readingDate,
          meterDeviceId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  createReading(readingId, meterDeviceId, readingValue = null, readingDate = null) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/add-meter-device-reading/',
        method: 'POST',
        data: {
          readingValue,
          readingDate,
          meterDeviceId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

    externalOperatorSuppliers() {
      return new Promise((resolve, reject) => {
        Vue.auth.fetch({
          url: '/api/meter-device/external-operator/suppliers',
          method: 'GET',
        }).then(response => {
          resolve(response.data)
        }).catch(err => {
          reject(err)
        })
      })
    }
}

export default new MeterDeviceRepository()
